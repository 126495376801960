<template lang="pug">
.season
  v-container
    v-row(align="end")
      v-col.offset-md-1(sm="6", md="5")
        high-chart-polar(
          v-if="polarObject.averagePercentList.length > 0",
          :personalList="polarObject.personalList",
          :personalPercentList="polarObject.personalPercentList",
          :averageList="polarObject.averageList",
          :averagePercentList="polarObject.averagePercentList",
          :bestList="polarObject.bestList",
          :personalSeriesTitle="'個人'"
        )
      v-col.offset-1.offset-sm-0.offset-md-0.offset-lg-1.mt-8.mt-sm-0(
        cols="10",
        sm="5",
        md="5",
        lg="4",
        xl="3"
      )
        position-field(
          :positionList="shotPositionList",
          :mainColor="'#487AA4'",
          :subColor="'#96B9DC'",
          @positionClick="positionClick"
        )
    v-row.table_row.mt-15
      v-col.table_col.table.position-relative(cols="12")
        table
          thead.top_head
            sticky-column.bdr2(colspan="1", :offset="0")
            th(colspan="2")
            th(colspan="5")
            th.bdr(colspan="3") 投籃
            th.bdr(colspan="3") 兩分
            th.bdr(colspan="3") 三分
            th.bdr(colspan="3") 罰球
            th.bdr(colspan="2") 籃板
            th(colspan="4")
          thead.bottom_head
            sticky-column.bdr2(colspan="1", title="", :offset="0")
            th(
              v-for="header in boxTableHeaders",
              :key="header.key",
              :class="{ bdr: header.border }"
            )
              span {{ header.name }}
          tbody(v-if="playerStats.overall")
            tr
              sticky-column.bdl.bdr2(
                colspan="1",
                :offset="0",
                :title="playerStats.overall.title"
              )
              td(
                v-for="header in boxTableHeaders",
                :key="header.key",
                :class="getDataClassObject(header, playerStats.overall[header.key], true)",
                @click="dataClick(header.key, header.link)"
              )
                span {{ playerStats.overall[header.key] }}
    v-row.table_row.mt-15
      v-col.table_col.table.position-relative(cols="12")
        table
          thead.top_head
            sticky-column.bdr2(colspan="1", :offset="0")
            th(colspan="2")
            th(colspan="5")
            th.bdr(colspan="3") 投籃
            th.bdr(colspan="3") 兩分
            th.bdr(colspan="3") 三分
            th.bdr(colspan="3") 罰球
            th.bdr(colspan="2") 籃板
            th(colspan="4")
          thead.bottom_head
            sticky-column.bdr2(colspan="1", title="", :offset="0")
            th(
              v-for="header in boxTableHeaders",
              :key="header.key",
              :class="{ bdr: header.border }"
            )
              span {{ header.name }}
          tbody(v-if="playerStats.location")
            tr(v-for="stats in playerStats.location", :key="stats.title")
              sticky-column.bdl.bdr2(
                colspan="1",
                :offset="0",
                :title="stats.title"
              )
              td(
                v-for="header in boxTableHeaders",
                :key="header.key",
                :class="getDataClassObject(header, stats[header.key], false)"
              )
                span {{ stats[header.key] }}
    v-row.table_row.mt-15
      v-col.table_col.table.position-relative(cols="12")
        table
          thead.top_head
            sticky-column.bdr2(colspan="1", :offset="0")
            th(colspan="2")
            th(colspan="5")
            th.bdr(colspan="3") 投籃
            th.bdr(colspan="3") 兩分
            th.bdr(colspan="3") 三分
            th.bdr(colspan="3") 罰球
            th.bdr(colspan="2") 籃板
            th(colspan="4")
          thead.bottom_head
            sticky-column.bdr2(colspan="1", title="", :offset="0")
            th(
              v-for="header in boxTableHeaders",
              :key="header.key",
              :class="{ bdr: header.border }"
            )
              span {{ header.name }}
          tbody(v-if="playerStats.outcome")
            tr(v-for="stats in playerStats.outcome", :key="stats.title")
              sticky-column.bdl.bdr2(
                colspan="1",
                :offset="0",
                :title="stats.title"
              )
              td(
                v-for="header in boxTableHeaders",
                :key="header.key",
                :class="getDataClassObject(header, stats[header.key], false)"
              )
                span {{ stats[header.key] }}
    v-row.table_row.mt-15
      v-col.table_col.table.position-relative(cols="12")
        table
          thead.top_head
            sticky-column.bdr2(colspan="1", :offset="0")
            th(colspan="2")
            th(colspan="5")
            th.bdr(colspan="3") 投籃
            th.bdr(colspan="3") 兩分
            th.bdr(colspan="3") 三分
            th.bdr(colspan="3") 罰球
            th.bdr(colspan="2") 籃板
            th(colspan="4")
          thead.bottom_head
            sticky-column.bdr2(colspan="1", title="", :offset="0")
            th(
              v-for="header in boxTableHeaders",
              :key="header.key",
              :class="{ bdr: header.border }"
            )
              span {{ header.name }}
          tbody(v-if="playerStats.vs_team")
            tr.hover.clickable(
              v-for="stats in playerStats.vs_team",
              :key="stats.title",
              @click="teamClick(stats.team_id)"
            )
              sticky-column.bdl.bdr2(
                colspan="1",
                :offset="0",
                :title="stats.title"
              )
              td(
                v-for="header in boxTableHeaders",
                :key="header.key",
                :class="getDataClassObject(header, stats[header.key], false)"
              )
                span {{ stats[header.key] }}
  event-video-dialog(
    v-model="isDialogShow",
    :seasonSegmentId="seasonSegmentId",
    :playerId="playerId",
    :position="position",
    :stat="stat"
  )
</template>

<script>
import { getPlayerStatistics } from "@/api/league";
import StickyColumn from "@/components/common/Table/StickyColumn";
import HighChartPolar from "@/components/common/HighChartPolar";
import PositionField from "@/components/common/PositionField";
import EventVideoDialog from "@/components/league/EventVideoDialog";

export default {
  name: "LeaguePlayerStatistics",
  components: {
    StickyColumn,
    HighChartPolar,
    PositionField,
    EventVideoDialog,
  },
  data() {
    return {
      polarObject: {
        bestList: [],
        personalList: [],
        personalPercentList: [],
        averageList: [],
        averagePercentList: [],
      },
      shotPositionList: [],
      boxTableHeaders: [
        {
          name: "出賽",
          key: "gp",
          border: false,
          bold: false,
          link: false,
        },
        {
          name: "時間",
          key: "min",
          border: true,
          bold: false,
          link: false,
        },
        {
          name: "得分",
          key: "pts",
          border: false,
          bold: true,
          link: true,
        },
        {
          name: "籃板",
          key: "reb",
          border: false,
          bold: true,
          link: true,
        },
        {
          name: "助攻",
          key: "ast",
          border: false,
          bold: true,
          link: true,
        },
        {
          name: "抄截",
          key: "stl",
          border: false,
          bold: true,
          link: true,
        },
        {
          name: "阻攻",
          key: "blk",
          border: true,
          bold: true,
          link: true,
        },
        {
          name: "命中",
          key: "fgm",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "出手",
          key: "fga",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "命中率%",
          key: "fg_pct",
          border: true,
          bold: false,
        },
        {
          name: "命中",
          key: "fg2m",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "出手",
          key: "fg2a",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "命中率%",
          key: "fg2_pct",
          border: true,
          bold: false,
        },
        {
          name: "命中",
          key: "fg3m",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "出手",
          key: "fg3a",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "命中率%",
          key: "fg3_pct",
          border: true,
          bold: false,
        },
        {
          name: "命中",
          key: "ftm",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "出手",
          key: "fta",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "命中率%",
          key: "ft_pct",
          border: true,
          bold: false,
        },
        {
          name: "進攻",
          key: "oreb",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "防守",
          key: "dreb",
          border: true,
          bold: false,
          link: true,
        },
        {
          name: "失誤",
          key: "tov",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "犯規",
          key: "pf",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "正負值",
          key: "plus_minus",
          border: false,
          bold: false,
          link: false,
        },
        {
          name: "效率值",
          key: "eff",
          border: true,
          bold: false,
          link: false,
        },
      ],
      playerStats: {},

      isDialogShow: false,
      position: "",
      stat: "",
    };
  },
  computed: {
    leagueId() {
      return Number(this.$route.params.leagueId);
    },
    seasonSegmentId() {
      return Number(this.$route.params.seasonSegmentId);
    },
    playerId() {
      return Number(this.$route.params.playerId);
    },
  },
  watch: {
    seasonSegmentId() {
      this.initData();
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      if (this.leagueId < 1 || this.seasonSegmentId < 1 || this.playerId < 1)
        return;
      await this.getPlayerStatisticsApi();
    },
    async getPlayerStatisticsApi() {
      const params = {
        player_id: this.playerId,
        season_segment_id: this.seasonSegmentId,
      };
      this.polarObject.bestList = [];
      this.polarObject.personalList = [];
      this.polarObject.personalPercentList = [];
      this.polarObject.averagePercentList = [];

      const response = await getPlayerStatistics(params);
      this.polarObject.bestList = response.data.polar_chart.best;
      this.polarObject.personalList = response.data.polar_chart.personal;
      this.polarObject.personalPercentList =
        response.data.polar_chart.personal_percent;
      this.polarObject.averageList = response.data.polar_chart.average;
      this.polarObject.averagePercentList =
        response.data.polar_chart.average_percent;
      this.shotPositionList = response.data.shot_position;

      console.log("polarObject=", this.polarObject);
      console.log("shotPositionList=", this.shotPositionList);

      this.playerStats = response.data.player_stats;
    },
    positionClick(position) {
      this.stat = "";
      this.position = position;
      this.isDialogShow = true;
    },
    getDataClassObject(header, value, isOverall = false) {
      let classObject = {};
      if (isOverall) {
        if (header.link && header.bold) classObject.link_deep = true;
        else if (header.link) classObject.link = true;
        else if (value <= 0) classObject.gray_out_heavy = true;
      } else {
        if (value > 0 && header.bold) classObject.blue_heavy = true;
        else if (value <= 0) classObject.gray_out_heavy = true;
      }
      classObject.bdr = header.border;
      return classObject;
    },
    dataClick(stat, link) {
      if (!link) return;
      this.stat = stat;
      this.position = "";
      this.isDialogShow = true;
    },
    teamClick(teamId) {
      this.$router.push({
        name: "LeagueTeam",
        params: {
          leagueId: this.leagueId,
          teamId: teamId,
          seasonSegmentId: this.seasonSegmentId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/common/pagination";
@import "@/assets/style/common/dialog_play_video";

input,
textarea,
select {
  background: #ffffff;
  border: 1px solid #487aa4;
}

input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
  background: url("~@/assets/img/search.png") no-repeat right center;
  background-position: calc(100% - 10px) center;
}

select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
  background: url("~@/assets/img/arrow_bottom_s.png") no-repeat right center;
  background-position: calc(100% - 10px) center;
}

@media (max-width: 959px) {
  .img_circle {
    width: 80px;
    height: 80px;
    margin-bottom: 5px;
  }

  .player_name,
  .bar_title.type,
  .player_data span.type,
  .membership_team .team {
    font-size: 16px;
  }
}
</style>
